<template>
  <div class="stages">
    <div style="background: #000000;height: 100%">
        <div class="header">
            <!-- <Header>
              <div>账单分期</div>
            </Header> -->
        </div>
        <div class="manage-cont" v-if="businessInfo">
            <commodityInfo
                    ref="commodityInfo"
                    :tmp_save_id="tmp_save_id"
                    :business_id="business_id"
                    :prestore_form="productInfo"
                    :businessInfo="businessInfo"
                    @tmpSaveId="getTmpSaveId"
                    @prestoreForm="getProductInfo"
                    @nextStep="toSkip"
                    v-if="isEmpty == 'product-info' && businessInfoFlag"
            />
            <identity-authentication
                    ref="identityAuthentication"
                    :tmp_save_id="tmp_save_id"
                    :business_id="business_id"
                    :infoUser="infoUser"
                    :businessInfo="businessInfo"
                    @nextStep="toSkip"
                    v-if="isEmpty == 'identityAuthentication' && infoUserFlage"
            />
            <personal-info
                    ref="personalInfo"
                    :tmp_save_id="tmp_save_id"
                    :business_id="business_id"
                    :prestore_form="personalForm"
                    @nextStep="toSkip"
                    @prestoreForm="getPersonalInfo"
                    @tmpSaveId="getTmpSaveId"
                    v-if="isEmpty == 'personalInfo'"
            />
            <binding-card
                    ref="bindingCard"
                    :tmp_save_id="tmp_save_id"
                    :business_id="business_id"
                    :infoUser="infoUser"
                    :userBindCardInfo="userBindCardInfo"
                    :businessInfo="businessInfo"
                    @nextStep="toSkip"
                    @jumpOCR="jumpOCR"
                    v-if="isEmpty == 'bindingCard' && userBindCardInfoFlag"
            />
            <submitStages
                    ref="submitStages"
                    :tmp_save_id="tmp_save_id"
                    :prestore_form="allInfo"
                    :infoUser="infoUser"
                    :business_id="business_id"
                    :productInfo="productInfo"
                    :businessInfo="businessInfo"
                    :fromocr='fromocr'
                    @nextStep="toSkip"
                    v-if="isEmpty == 'submit-stages'"
            />
            <pending-trial ref="PendingTrial" @nextStep="toSkip" v-if="isEmpty == 'pendingTrial'" />
        </div>
    </div>
  </div>
</template>

<script>
// import Header from "../../components/header.vue";
import commodityInfo from "./components/commodity-info.vue";
import IdentityAuthentication from "./components/identity-authentication.vue";
import PersonalInfo from "./components/personal-info.vue";
import BindingCard from "./components/binding-card.vue";
import submitStages from "./components/submit-stages.vue";
import PendingTrial from "./components/pending-trial.vue";

import { infoApplication, infoUser } from "@/api/application.js";
import { checkUserBindCard } from "@/api/bank.js";
import { previlegeInfoBusiness } from "@/api/business.js";
// import { Toast } from "vant";

export default {
  name: "stages",
  components: {
    commodityInfo,
    IdentityAuthentication,
    PersonalInfo,
    BindingCard,
    submitStages,
    PendingTrial,
  },
  data() {
    return {
      isEmpty: "product-info",
      business_id: "",
      tmp_save_id: "",
      productInfo: {},
      personalForm: {},
      bindingCardForm: {},
      allInfo: {},
      // flag: false,
      businessInfoFlag: false,
      infoUserFlage:false,
      infoUser: {},
      userBindCardInfo: {},
      businessInfo: null,
      userBindCardInfoFlag:false,
      fromocr:false
    };
  },
  async created() {
    this.tmp_save_id = this.$route.query.tmp_save_id || "";
    this.business_id = this.$route.query.business_id || "";
    this.getInfoUser();
    this.getCheckUserBindCard();
    // if (this.tmp_save_id) {
    //   this.initData();
    // } else {
    //   this.flag = true;
    //   this.getPrevilegeInfoBusiness(this.business_id);
    // }
    if (this.tmp_save_id) {
      await this.initData();
    }
    this.getPrevilegeInfoBusiness();

    if (this.$route.query.isEmpty) {
      this.isEmpty = this.$route.query.isEmpty;
    }
  },
  mounted() {},
  methods: {
    async initData() {
      const parme = {
        tmp_save_id: this.tmp_save_id,
      };
      const { code, data } = await infoApplication(parme);
      if (code == 200) {
        this.business_id = data.business_id;
        // this.getPrevilegeInfoBusiness(data.business_id);
        if (data.step == 1) {
          this.isEmpty = "product-info";
          console.log("product-info");
        } else if (data.step == 2) {
          this.isEmpty = "identityAuthentication";
          console.log("identityAuthentication");
        } else if (data.step == 3) {
          this.isEmpty = "personalInfo";
        } else if (data.step == 4) {
          this.isEmpty = "bindingCard";
        } else if (data.step == 5) {
          console.log(data)
          if (data.code != 0) {
            this.isEmpty = "bindingCard";
            // Toast("人脸核身失败");
          } else {
            this.isEmpty = "submit-stages";
          }
        }
        this.allInfo = data;
        this.productInfo = {
          productId: data.productId || '',
          packageId: data.packageId || '',
          price: data.price || '',
          repay_type: data.repay_type || '',
        };
        this.personalForm = {
          address_province_id: data.address_province_id || "",
          address_city_id: data.address_city_id || "",
          address_district_id: data.address_district_id || "",
          residence_address: data.residence_address || "",
          education: data.education || "",
          graduation_date: data.graduation_date || "",
          first_relation_type: data.first_relation_type || "",
          first_relation_user_name: data.first_relation_user_name || "",
          first_relation_user_mobile: data.first_relation_user_mobile || "",
          second_relation_type: data.second_relation_type || "",
          second_relation_user_name: data.second_relation_user_name || "",
          second_relation_user_mobile: data.second_relation_user_mobile || "",
          email: data.email || "",
        };
      }
    },
    async getInfoUser() {
      const { code, data } = await infoUser();
      if (code == 200) {
        this.infoUserFlage = true
        this.infoUser = data;
      }
    },
    async getCheckUserBindCard() {
      const { code, data } = await checkUserBindCard();
      if (code == 200) {
        this.userBindCardInfoFlag = true
        this.userBindCardInfo = data;
      }
    },
    async getPrevilegeInfoBusiness(business_id) {
      const parmes = {
        business_id:  business_id || this.business_id,
      };
      const { code, data } = await previlegeInfoBusiness(parmes);
      if (code == 200) {
        this.businessInfoFlag = true;

        this.businessInfo = data;
      }
    },
    getProductInfo(params) {
      this.productInfo = params;
    },
    getPersonalInfo(params) {
      this.personalForm = params;
    },
    getTmpSaveId(params) {
      this.tmp_save_id = params;
    },
    toSkip(params) {
      this.isEmpty = params;
    },
    jumpOCR(params) {
      console.log(params)
      this.fromocr = params.fromocr
      this.isEmpty = params.toSkip;
      // this.isEmpty = params;
    },
  },
};
</script>

<style lang="scss" scoped>
.stages {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  .rate {
    z-index: 100;
    position: absolute;
    top: 230px;
    right: 70px;
    width: 120px;
    height: 120px;
    .currentRate {
      width: 120px;
      height: 120px;
    }
  }
  .manage-cont {
    flex: 1;
    overflow: hidden;
  }
  /deep/.van-cell{
    font-size: 16px;
    padding: 14px 16px;
  }
}
</style>