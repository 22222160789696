import ajax from '@/utils/ajax.js'

/**
 * 下拉产品
 */
 export function selectProdcut(params) {
  return ajax.post('/api/selectProduct',params)
}

/**
 * 下拉报价
 */
 export function selectPackage(params) {
  return ajax.post('/api/selectPackage',params)
}