<template>
  <div class="pendingTrial">
    <!-- <div class="go-back">
      <div @click="toInfo">
        <van-icon name="arrow-left" class="icon-left"/>
        <span>提交结果页</span>
      </div>
    </div> -->
    <div class="content">
      <div class="content-item">
        <div class="audit">
          <img src="@/assets/img/shenpi.png" alt="" />
          <div>审核中</div>
        </div>
        <div class="hint">
          订单提交成功，正在加急审核中，请您长按图中二维码，扫描关注"先学后付服务中心"微信公众号，进行后续订单产看及付款操作。
        </div>
      </div>
      <div class="content-qr-code">
        <div class="qr-code">
          <img src="@/assets/img/QRCodegzh.png" alt="" />
        </div>
        <div class="qr-text">
          <div>长按二维码</div>
          <div>扫描关注“先学后付服务中心”公众号</div>
        </div>
      </div>
      <div style="margin: 16px">
        <div
          class="submit-button"
          style="background: #1677ff"
          @click="toProgress"
        >
          查看进度
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pendingTrial",

  data() {
    return {};
  },

  mounted() {},

  methods: {
    toProgress() {
      this.$router.replace("/");
    },
    toInfo() {
      this.$emit("nextStep", "submit-stages");
    },
  },
};
</script>

<style lang="scss" scoped>
.pendingTrial {
  height: 100%;
  width: 100%;
  position: fixed;
  background: #f5f5f5;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    height: 100px;
  }
  .content {
    padding: 30px;
    .content-item {
      background: #fff;
      box-shadow: 0px 0px 10px 0px #f5f5f5;
      border-radius: 20px;
      padding: 70px;
      font-size: 30px;
      color: #333;
      display: flex;
      flex-direction: column;
      align-items: center;
      .audit {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 48px;
        img {
          width: 271px;
          height: 197px;
        }
      }
      .hint {
        margin-top: 20px;
        font-size: 28px;
        color: #666;
      }
    }
    .content-qr-code {
      display: flex;
      align-items: center;
      padding: 70px 0px;
      .qr-code {
        width: 144px;
        height: 144px;
        background: #d1d1d1;
        border-radius: 10px;
        margin: 0px 70px;
        img {
          width: 144px;
          height: 144px;
          border-radius: 10px;
        }
      }
      .qr-text {
        font-size: 28px;
        color: #666;
      }
    }
    .submit-button {
      width: 100%;
      height: 90px;
      background: #708eb8;
      box-shadow: 0px 7px 13px 0px rgba(112, 142, 184, 0.32);
      border-radius: 45px;
      font-size: 36px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>