<template>
  <div class="bindingCard">
    <div class="go-back">
      <div @click="toInfo">
        <van-icon name="arrow-left" class="icon-left" />
      </div>
    </div>
    <div class="content">
      <div class="content-title">
        <div class="title-text">
          <div class="title">绑定银行卡</div>
          <div class="hint">身份信息仅做身份认证，请放心申请</div>
        </div>
        <van-circle
          class="currentRate"
          layer-color="#FFDABD"
          color="#FF8C10"
          :stroke-width="160"
          v-model="currentRate"
          :rate="currentRate"
          :speed="100"
          :text="text"
        />
      </div>
      <div class="content-form">
        <div class="content-item" v-if="!cardSow">
          <van-form>
            <van-field
              readonly
              clickable
              name="bank_name"
              :value="bank_name"
              label="银行"
              placeholder="点击选择银行"
              @click="showSupportBank = true"
              input-align="right"
            />
            <van-field
              v-model="card_info.bank_card_no"
              label="借记卡号"
              placeholder="请输入借记卡号"
              input-align="right"
              @input="onCardNumberInput"
            />
            <div style="margin:7px 16px 20px;text-align:right;">
              <span style="color:#1677ff;font-size:14px;" @click="popSupportBank">支持的银行</span>
            </div>
            <van-field
              v-model="card_info.cardholder"
              label="持卡人"
              placeholder="请输入持卡人"
              input-align="right"
              @input="oncardholderInput"
            />
            <van-field
              v-model="card_info.reserve_mobile"
              label="预留手机号"
              placeholder="请输入预留手机号"
              input-align="right"
              maxlength="11"
              type="tel"
              @input="onPhoneInput"
              :rules="[
                { validator: verifyPhone, message: '请输入正确的手机号码' },
              ]"
            />
            <van-field
              v-model="smsCode"
              name="code"
              label="验证码"
              placeholder="验证码"
              maxlength="6"
              @input="onCodeInput"
            >
              <template #button>
                <span style="color: #1677ff" v-if="!codeState" @click="getCode"
                  >获取验证码</span
                >
                <span class="code" v-if="codeState" style="color: #bebebe"
                  >还剩{{ codeTime }}秒</span
                >
              </template>
            </van-field>
          </van-form>
        </div>
        <div class="content-item" v-else>
          <van-field
            v-model="bindingCard.bank_name"
            label="银行"
            input-align="right"
            readonly
          />
          <van-field
            v-model="bindingCard.bank_card_no"
            label="借记卡号"
            input-align="right"
            readonly
          />
        </div>
        <div style="margin: 10px 16px" v-if="!cardSow">
          <div
            class="next-step-button"
            @click="onSubmit"
            v-if="
              supportBankEmpty &&
              cardholderEmpty &&
              cardNumberEmpty &&
              phoneEmpty &&
              codeEmpty &&
              !loading &&
              skip_contact == 0
            "
          >
            下一步
          </div>
          <div class="next-step-button"  
          @click="onSubmitWithoutContect"
          v-else-if="supportBankEmpty &&
              cardholderEmpty &&
              cardNumberEmpty &&
              phoneEmpty &&
              codeEmpty &&
              !loading &&
              skip_contact == 1">下一步</div>
          <div class="next-step-button" v-else-if="loading">...提交中</div>
          <div class="next-step-button" v-else>下一步</div>
        </div>
        <div style="margin: 10px 16px" v-else>
          <div
            class="next-step-button"
            @click="onBindingSubmit"
            v-if="bankName && bankCardNo && !loading && skip_contact == 0"
          >
            下一步
          </div>
          <div
            class="next-step-button"
            @click="onBindingSubmitWithoutContect"
            v-if="bankName && bankCardNo && !loading && skip_contact == 1"
          >
          下一步
          </div>
          <div class="next-step-button" v-else-if="loading">...提交中</div>
          <div class="next-step-button" v-else>下一步</div>
        </div>

        <div class="skip-binding-card" v-if="businessInfo.skip_bind_bank == 1">
          <span @click="onSkipBindingCard">忘记带银行卡怎么办</span>
        </div>
      </div>
    </div>

    <van-popup v-model="showSupportBank" position="bottom">
      <van-picker
        show-toolbar
        value-key="bank_name"
        :columns="supportBankList"
        @confirm="onChooseBank"
        @cancel="showSupportBank = false"
      />
    </van-popup>

    <!-- 支持的银行 -->
    <van-action-sheet v-model="isPopBankList" title="支持的银行">
      <div class="bank-list">
        <div class="bank-list-head">
          <span>银行</span>
          <span>每日限额</span>
          <span>单笔限额</span>
        </div>
        <div class="bank-list-body" v-for="(item,index) in supportBankList" :key="index">
          <span>{{item.bank_name}}</span>
          <span>{{item.day_pay_limit_max}}</span>
          <span>{{item.single_pay_limit_max}}</span>
        </div>
      </div>
    </van-action-sheet>

    <confirm :show.sync="isShowSkipCard" 
        subTitle="是否跳过绑卡？"
        okText="跳过"
        cancelText="取消"
        @ok="onSkipBindingCardOk"></confirm>
  </div>
</template>

<script>
import { tmpSaveApplication } from "@/api/application.js";
import { applyBill } from "@/api/bill.js";
import {
  listSupportBank,
  bindCardSmsCode,
  confirmBindCard,
} from "@/api/bank.js";
import { Toast } from "vant";
import { BASE_URL } from "@/utils/config";
import Confirm from "@/components/confirm";

export default {
  name: "bindingCard",
  components: {
    Confirm
  },
  props: ["tmp_save_id", "business_id", "userBindCardInfo", "businessInfo","infoUser"],
  data() {
    return {
      currentRate: 80,
      loading: false,
      card_info: {
        cardholder: "",
        bank_card_no: "",
        reserve_mobile: "",
        support_bank_id: "",
      },
      smsCode: "",
      supportBankList: [],
      bank_name: "",
      showSupportBank: false,
      supportBankEmpty: false,
      cardholderEmpty: false,
      cardNumberEmpty: false,
      phoneEmpty: false,
      codeEmpty: false,
      codeState: false,
      codeTime: 60,
      timer: null,
      order_id: "",
      cardSow: false,
      bindingCard: {},
      bankName: false,
      bankCardNo: false,

      isPopBankList: false,
      isShowSkipCard: false,
      skip_contact: 0,
      basics_info: {
        address_province_id: "",
        address_city_id: "",
        address_district_id: "",
        residence_address: "",
        education: "",
        graduation_date: "",
        first_relation_type: "",
        first_relation_user_name: "",
        first_relation_user_mobile: "",
        second_relation_type: "",
        second_relation_user_name: "",
        second_relation_user_mobile: "",
        email: "",
      },
    };
  },
  mounted() {
    this.getSupportBank();
    this.initData();
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + "%";
    },
  },
  methods: {
    initData() {
      console.log('商家信息',this.businessInfo)
      if (this.businessInfo.skip_contact == 1) {
        this.skip_contact = 1
        this.currentRate = 100
        this.basics_info.address_province_id = "110000"
        this.basics_info.address_city_id = "110100"
        this.basics_info.address_district_id = "110101"
        this.basics_info.residence_address = ""
        this.basics_info.education = "1"
        this.basics_info.graduation_date = "1977-1-1"
        this.basics_info.first_relation_type = "1"
        this.basics_info.first_relation_user_name = "素质教育1"
        this.basics_info.first_relation_user_mobile = "18888888888"
        this.basics_info.second_relation_type = "2"
        this.basics_info.second_relation_user_name = "素质教育2"
        this.basics_info.second_relation_user_mobile = "17777777777"
        this.basics_info.email = "1777777777@test.com"
      }
      if (JSON.stringify(this.userBindCardInfo) != "{}") {
        this.cardSow = true;
        this.bindingCard = this.userBindCardInfo;
        this.bankName = true;
        this.bankCardNo = true;
      }
    },
    async onBindingSubmit() {
      this.temporaryMemory(this.bindingCard.card_id);
    },
    async onBindingSubmitWithoutContect() {
      this.temporaryMemoryPre(this.bindingCard.card_id);
    },
    async onSubmit() {
      var that = this;
      console.log(1)
      that.loading = true
      const confirmCardInfo = {
        orderId: that.order_id,
        smsCode: that.smsCode,
      };
      const { code, data } = await confirmBindCard(confirmCardInfo);
      that.loading = false
      if (code == 200) {
        that.loading = false
        that.temporaryMemory(data.card_id);
      } else {
        that.loading = false
      }
    },
    async onSubmitWithoutContect() {
      console.log(2)
      var that = this;
      that.loading = true
      const confirmCardInfo = {
        orderId: that.order_id,
        smsCode: that.smsCode,
      };
      const { code, data } = await confirmBindCard(confirmCardInfo);
      that.loading = false
      if (code == 200) {
        that.loading = false
        that.temporaryMemoryPre(data.card_id);
      } else {
        that.loading = false
      }
    },
    async temporaryMemoryPre(card_id) {
      this.loading = true;
      const parmes = {
        ...this.basics_info,
        step: 3,
        tmp_save_id: this.tmp_save_id,
      };
      const { code } = await tmpSaveApplication(parmes);
      this.loading = false;
      if (code == 200) {
        this.temporaryMemoryEnd(card_id)
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async temporaryMemoryEnd(card_id) {
      this.loading = true;
      const parmes = {
        skip_bind_bank: 0,
        user_card_id: card_id,
        step: 4,
        tmp_save_id: this.tmp_save_id,
      };
      const { code } = await tmpSaveApplication(parmes);
      this.loading = false;
      if (code == 200) {
        this.submitBill()
      }
    },
    async submitBill() {
      var that = this;
      that.loading = true;
      const parmes = {
        tmp_save_id: this.tmp_save_id,
      };
      console.log(parmes);
      const { code, data } = await applyBill(parmes);
      that.loading = false;
      if (code == 200) {
        console.log(data);
        that.loading = false;
        Toast("提交成功");
        that.$emit("nextStep", "pendingTrial");
      } else {
        that.loading = false;
      }
    },
    async temporaryMemory(card_id) {
      this.loading = true;
      const parmes = {
        skip_bind_bank: 0,
        user_card_id: card_id,
        step: 4,
        tmp_save_id: this.tmp_save_id,
      };
      const { code } = await tmpSaveApplication(parmes);
      this.loading = false;
      if (code == 200) {
        if (this.businessInfo.skip_ocr == 0 && this.infoUser.id_card_image_url) {
          this.getFace();
        }else{
          const jumpOCR = {
            toSkip: 'submit-stages',
            fromocr: true
          }
          this.$emit("jumpOCR", jumpOCR);
        }
      }
    },
    async getFace() {
      const routeData = this.$router.resolve({
        path: "/stages",
        query: {
          isEmpty: "submit-stages",
        },
      });
      var redirect_url = window.location.origin + "/" + routeData.href;
      var token = localStorage.getItem("token");
      const faceUrl = `${BASE_URL}/api/getFace2?callback=${encodeURIComponent(
        redirect_url
      )}&tmp_save_id=${this.tmp_save_id}&token=${token}`;
      window.location.href = faceUrl;
    },

    onSkipBindingCard(){
      this.isShowSkipCard = true
    },
    async onSkipBindingCardOk() {
      var that = this;
      const parmes = {
        skip_bind_bank: 1,
        step: 4,
        tmp_save_id: that.tmp_save_id,
      };
      const { code } = await tmpSaveApplication(parmes);
      if (code == 200) {
        if (this.businessInfo.skip_ocr == 0 && this.infoUser.id_card_image_url) {
          this.getFace();
        }else{
          const jumpOCR = {
            toSkip: 'submit-stages',
            fromocr: true
          }
          this.$emit("jumpOCR", jumpOCR);
        }
      }
    },
    async getSupportBank() {
      const { code, data } = await listSupportBank();
      if (code == 200) {
        this.supportBankList = data;
      }
    },
    onChooseBank(value) {
      this.bank_name = value.bank_name;
      this.card_info.support_bank_id = value.bank_id;
      this.showSupportBank = false;
      if (this.card_info.support_bank_id == "") {
        this.supportBankEmpty = false;
      } else {
        this.supportBankEmpty = true;
      }
    },
    oncardholderInput(value) {
      this.card_info.cardholder = value;
      if (this.card_info.cardholder == "") {
        this.cardholderEmpty = false;
      } else {
        this.cardholderEmpty = true;
      }
    },
    onCardNumberInput(value) {
      this.card_info.bank_card_no = value;
      if (this.card_info.bank_card_no == "") {
        this.cardNumberEmpty = false;
      } else {
        this.cardNumberEmpty = true;
      }
    },
    onPhoneInput(value) {
      this.card_info.reserve_mobile = value;
      if (this.card_info.reserve_mobile == "") {
        this.phoneEmpty = false;
      } else {
        this.phoneEmpty = true;
      }
    },
    onCodeInput(value) {
      this.smsCode = value;
      if (this.smsCode == "") {
        this.codeEmpty = false;
      } else {
        this.codeEmpty = true;
      }
    },
    async getCode() {
      if (
        this.supportBankEmpty &&
        this.cardholderEmpty &&
        this.cardNumberEmpty &&
        this.phoneEmpty
      ) {
        var that = this;
        const parmes = {
          bankId: this.card_info.support_bank_id,
          bankCardNo: (this.card_info.bank_card_no || "").replace(/\s/g, ''),
          cardholder: this.card_info.cardholder,
          reserveMobile: this.card_info.reserve_mobile,
        };
        const { code, data } = await bindCardSmsCode(parmes);
        if (code == 200) {
          that.codeState = true;
          that.order_id = data.order_id;
          that.timer = setInterval(() => {
            if (that.codeTime == 0) {
              clearInterval(that.timer);
              that.codeState = false;
              that.codeTime = 60;
            } else {
              that.codeTime--;
            }
          }, 1000);
        } else {
          that.codeState = false;
          that.codeTime = 60;
        }
      } else {
        Toast("请先完善绑卡信息");
      }
    },

    popSupportBank(){
      this.isPopBankList = true
    },
    verifyPhone(val) {
      // var rule =
      //   /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(val);
      var rule = /^1\d{10}$/.test(val);
      if (rule) {
        this.phoneEmpty = true;
      } else {
        this.phoneEmpty = false;
      }
      return rule;
    },
    toInfo() {
      if (this.businessInfo.skip_contact == 1) {
            this.$emit("nextStep", "identityAuthentication");
          } else {
            this.$emit("nextStep", "personalInfo");
          }
    },
  },
};
</script>

<style lang="scss" scoped>
.bindingCard {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  color: #333;
  display: flex;
  flex-direction: column;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #000;
    color: #fff;
    height: 100px;
  }
  .content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #000;
    .content-title {
      margin: 30px 30px 0;
      padding: 30px 40px 26px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px 32px 0 0;
      color: #FDA259;
      background: linear-gradient(115deg, #FFE5CC 0%, #FFC191 100%);
      .title-text {
        flex: 1;
        .title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #552C00;
          line-height: 46px;
        }
        .hint {
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #552C00;
        }
      }
      .currentRate {
        width: 120px;
        height: 120px;
      }
    }
    .content-form {
      flex: 1;
      overflow-y: auto;
      padding: 0px 30px;
      border-radius: 32px;
      margin-top: -15px;
      .content-item {
        margin-bottom: 20px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px #f5f5f5;
        border-radius: 20px;
        padding: 15px 0px;
        font-size: 30px;
        color: #333;
      }
      .skip-binding-card {
        margin: 40px 0px 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        //color: #1677ff;
        //font-size: 30px;
        font-size: 24px;
        color: #E89E50;
      }
      .next-step-button {
        width: 100%;
        height: 90px;
        background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);
        border-radius: 49px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bank-list{
    margin: 20px 30px 30px;
    font-size: 30px;
    line-height: 40px;
    .bank-list-head, .bank-list-body{
      display: flex;
      span{
        flex: 1;
        padding: 20px 10px;
        &:nth-child(1){
          flex: 2
        }
      }
    }
    .bank-list-head{
      font-weight: 500;
      border-bottom: 1px solid #ccc;
    }
  }
}
</style>