<template>
  <div class="personalInfo">
    <div class="go-back">
      <div @click="toInfo">
        <van-icon name="arrow-left" class="icon-left" />
      </div>
    </div>
    <div class="content">
      <div class="content-title">
        <div class="title-text">
          <div class="title">个人信息</div>
          <div class="hint">身份信息仅做身份认证，请放心申请</div>
        </div>
        <van-circle
          class="currentRate"
          layer-color="#FFDABD"
          color="#FF8C10"
          :stroke-width="160"
          v-model="currentRate"
          :rate="currentRate"
          :speed="100"
          :text="text"
        />
      </div>
      <div class="content-form">
        <div class="content-item">

          <div class="item-content">
            <van-field
              label-width="100"
              readonly
              clickable
              name="province_name"
              :value="province_name"
              label="现居住省"
              placeholder="点击选择省/直辖市"
              @click="showProvince = true"
              input-align="right"
            />
            <van-field
              label-width="100"
              readonly
              clickable
              name="city_name"
              :value="city_name"
              label="现居住市"
              placeholder="点击选择市"
              @click="showCity = true"
              input-align="right"
            />
            <van-field
              label-width="100"
              readonly
              clickable
              name="district_name"
              :value="district_name"
              label="现居住区县"
              placeholder="点击选择区县"
              @click="showDistrict = true"
              input-align="right"
            />
            <van-field
              v-model="basics_info.residence_address"
              label="详细居住地址"
              placeholder="请输入详细居住地址"
              input-align="right"
              @input="onResidenceAddress"
            />
            <van-field
              readonly
              clickable
              name="education_text"
              :value="education_text"
              label="学历"
              placeholder="点击选择学历"
              @click="showEducation = true"
              input-align="right"
            />
            <van-field
              readonly
              clickable
              name="graduation_date"
              :value="graduation_date"
              label="毕业时间"
              placeholder="点击选择毕业时间"
              @click="showGraduation = true"
              input-align="right"
            />
          </div>
        </div>
        <div class="content-item">
          <div class="item-title">
            <span
              style="
                padding: 0px 2px;
                height: 10px;
                background-color: #E69B4A;
                margin-right: 10px;
              "
            ></span>
            <span>其他信息</span>
          </div>
          <div class="item-content">
            <van-form>
              <div class="cardFrom">
                <van-field
                readonly
                clickable
                name="first_relation_type"
                :value="first_relation_type"
                label="联系人1关系"
                placeholder="点击选择关系"
                @click="showFirstRelation = true"
                style="background: #F9F9F9;border-radius: 16px 16px 0 0 ;"
                input-align="right"
              />
              <van-field
                v-model="basics_info.first_relation_user_name"
                label="联系人1姓名"
                placeholder="请输入联系人1姓名"
                input-align="right"
                style="background: #F9F9F9;"
                @input="onFirstName"
              />
              <van-field
                v-model="basics_info.first_relation_user_mobile"
                label="联系人1电话"
                placeholder="请输入联系人1电话"
                input-align="right"
                type="tel"
                maxlength="11"
                @input="onFirstMobile"
                style="background: #F9F9F9;border-radius: 0 0 16px 16px;"
                :rules="[
                  { validator: verifyPhone, message: '请输入正确的手机号码' },
                ]"
              />
              </div>
              <van-field
                readonly
                clickable
                name="second_relation_type"
                :value="second_relation_type"
                label="联系人2关系"
                placeholder="点击选择关系"
                style="background: #F9F9F9;border-radius:16px 16px 0 0;margin-top: 16px;"
                @click="showSecondRelation = true"
                input-align="right"
              />
              <van-field
                v-model="basics_info.second_relation_user_name"
                label="联系人2姓名"
                placeholder="请输入联系人2姓名"
                style="background: #F9F9F9;"
                input-align="right"
                @input="onSecondName"
              />
              <van-field
                v-model="basics_info.second_relation_user_mobile"
                label="联系人2电话"
                placeholder="请输入联系人2电话"
                input-align="right"
                type="tel"
                style="background: #F9F9F9;"
                maxlength="11"
                @input="onSecondMobile"
                :rules="[
                  {
                    validator: verifySecondPhone,
                    message: '请输入正确的手机号码',
                  },
                ]"
              />
              <van-field
                v-model="basics_info.email"
                label="邮箱"
                placeholder="请输入邮箱"
                input-align="right"
                style="background: #F9F9F9;border-radius: 0 0 16px 16px;"
                @input="onEmail"
                :rules="[
                  { validator: verifyEmail, message: '请输入正确的邮箱格式' },
                ]"
              />
            </van-form>
          </div>
        </div>
        <div style="margin: 10px 16px">
          <div class="next-step-button" v-if="loading">...提交中</div>
          <template v-else>
            <div
              class="next-step-button"
              @click="onSubmit"
              v-if="
                provinceEmpty &&
                cityEmpty &&
                districtEmpty &&
                residenceAddressEmpty &&
                educationEmpty &&
                graduateEmpty &&
                firstRelationEmpty &&
                firstNameEmpty &&
                firstMobileEmpty &&
                secondRelationEmpty &&
                secondNameEmpty &&
                secondMobileEmpty &&
                emailEmpty
              "
            >下一步</div>
            <div class="next-step-button" v-else>下一步</div>
          </template>
        </div>
      </div>
    </div>
    <van-popup v-model="showProvince" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="provinceList"
        :default-index="provinceIndex"
        @confirm="onProvince"
        @cancel="showProvince = false"
      />
    </van-popup>
    <van-popup v-model="showCity" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="cityList"
        :default-index="cityIndex"
        @confirm="onCity"
        @cancel="showCity = false"
      />
    </van-popup>
    <van-popup v-model="showDistrict" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="districtList"
        :default-index="districtIndex"
        @confirm="onDistrict"
        @cancel="showDistrict = false"
      />
    </van-popup>
    <van-popup v-model="showEducation" position="bottom">
      <van-picker
        show-toolbar
        :columns="educationList"
        :default-index="educationIndex"
        @confirm="onEducation"
        @cancel="showEducation = false"
      />
    </van-popup>
    <van-popup v-model="showGraduation" round position="bottom">
      <van-datetime-picker
        type="date"
        v-model="currentDate"
        title="选择毕业时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onGraduation"
        @cancel="showGraduation = false"
      />
    </van-popup>
    <van-popup v-model="showFirstRelation" round position="bottom">
      <van-picker
        show-toolbar
        value-key="text"
        :columns="relationType"
        :default-index="relationIndex"
        @confirm="onRelationType"
        @cancel="showFirstRelation = false"
      />
    </van-popup>
    <van-popup v-model="showSecondRelation" round position="bottom">
      <van-picker
        show-toolbar
        value-key="text"
        :columns="relationType"
        :default-index="secondIndex"
        @confirm="onSecondRelation"
        @cancel="showSecondRelation = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { educationList, relationList } from "@/assets/equipment/allSelect.json";
import {
  listProvince,
  listCity,
  listDistrict,
  tmpSaveApplication,
} from "@/api/application.js";
export default {
  name: "personalInfo",
  props: ["prestore_form", "business_id", "tmp_save_id"],
  data() {
    return {
      loading: false,
      currentRate: 60,
      basics_info: {
        address_province_id: "",
        address_city_id: "",
        address_district_id: "",
        residence_address: "",
        education: "",
        graduation_date: "",
        first_relation_type: "",
        first_relation_user_name: "",
        first_relation_user_mobile: "",
        second_relation_type: "",
        second_relation_user_name: "",
        second_relation_user_mobile: "",
        email: "",
      },
      provinceEmpty: false,
      showProvince: false,
      provinceIndex: 0,
      province_name: "",
      provinceList: [],
      cityEmpty: false,
      showCity: false,
      cityIndex: 0,
      city_name: "",
      cityList: [],
      districtEmpty: false,
      showDistrict: false,
      districtIndex: 0,
      district_name: "",
      districtList: [],
      residenceAddressEmpty: false,
      educationEmpty: false,
      showEducation: false,
      educationIndex: 0,
      education_text: "",
      educationList: educationList,
      graduateEmpty: false,
      showGraduation: false,
      graduation_date: "",
      currentDate: "",
      minDate: new Date(1977, 0, 1),
      maxDate: "",
      firstRelationEmpty: false,
      showFirstRelation: false,
      relationIndex: 0,
      first_relation_type: "",
      relationType: relationList,
      firstNameEmpty: false,
      firstMobileEmpty: false,
      secondRelationEmpty: false,
      showSecondRelation: false,
      secondIndex: 0,
      second_relation_type: "",
      secondNameEmpty: false,
      secondMobileEmpty: false,
      emailEmpty: false,
    };
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + "%";
    },
  },
  mounted() {
    this.onDate();
    this.getListProvince();
    this.initData();
  },

  methods: {
    async initData() {
      var that = this;
      var data = this.prestore_form;
      if (JSON.stringify("data") != "{}" && data.address_province_id != "") {
        await that.getListCity(data.address_province_id);
        await that.getListDistrict(data.address_city_id);
        that.basics_info = data;
        var provinceObj = that.provinceList.find((obj, index) => {
          if (obj.id == data.address_province_id) {
            that.provinceIndex = index;
          }
          return obj.id == data.address_province_id;
        });
        that.province_name = provinceObj.name;
        var cityObj = that.cityList.find((obj, index) => {
          if (obj.id == data.address_city_id) {
            that.cityIndex = index;
          }
          return obj.id == data.address_city_id;
        });
        that.city_name = cityObj.name;
        var districtObj = that.districtList.find((obj, index) => {
          if (obj.id == data.address_district_id) {
            that.districtIndex = index;
          }
          return obj.id == data.address_district_id;
        });
        that.district_name = districtObj.name;
        var educationObj = that.educationList.find((obj, index) => {
          if (obj.value == data.education) {
            that.educationIndex = index;
          }
          return obj.value == data.education;
        });
        that.education_text = educationObj.text;
        var relationObj = that.relationType.find((obj, index) => {
          if (obj.value == data.first_relation_type) {
            that.relationIndex = index;
          }
          return obj.value == data.first_relation_type;
        });
        that.first_relation_type = relationObj.text;
        var secondRelationObj = that.relationType.find((obj, index) => {
          if (obj.value == data.second_relation_type) {
            that.secondIndex = index;
          }
          return obj.value == data.second_relation_type;
        });
        that.second_relation_type = secondRelationObj.text;
        this.graduation_date = data.graduation_date;
        var date = data.graduation_date.split("-");
        (this.currentDate = new Date(date[0], date[1] - 1, date[2])),
          (this.provinceEmpty = true);
        this.cityEmpty = true;
        this.districtEmpty = true;
        this.residenceAddressEmpty = true;
        this.educationEmpty = true;
        this.graduateEmpty = true;
        this.firstRelationEmpty = true;
        this.firstNameEmpty = true;
        this.firstMobileEmpty = true;
        this.secondRelationEmpty = true;
        this.secondNameEmpty = true;
        this.secondMobileEmpty = true;
        this.emailEmpty = true;
      }
    },
    async onSubmit() {
      this.loading = true;
      const parmes = {
        ...this.basics_info,
        step: 3,
        tmp_save_id: this.tmp_save_id,
      };
      const { code } = await tmpSaveApplication(parmes);
      this.loading = false;
      if (code == 200) {
        this.$emit("prestoreForm", this.basics_info);
        this.$emit("tmpSaveId", this.tmp_save_id);
        this.$emit("nextStep", "bindingCard");
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async getListProvince() {
      const { code, data } = await listProvince();
      if (code == 200) {
        this.provinceList = data;
      }
    },
    onProvince(value) {
      this.province_name = value.name;
      this.basics_info.address_province_id = value.id;
      this.provinceEmpty = true;
      this.showProvince = false;
      this.getListCity(value.id);
      this.city_name = "";
      this.basics_info.address_city_id = "";
      this.district_name = "";
      this.basics_info.address_district_id = "";
    },
    async getListCity(province_id) {
      const { code, data } = await listCity({ province_id: province_id });
      if (code == 200) {
        this.cityList = data;
      }
    },
    onCity(value) {
      this.city_name = value.name;
      this.basics_info.address_city_id = value.id;
      this.cityEmpty = true;
      this.showCity = false;
      this.getListDistrict(value.id);
      this.district_name = "";
      this.basics_info.address_district_id = "";
    },
    async getListDistrict(city_id) {
      const { code, data } = await listDistrict({ city_id: city_id });
      if (code == 200) {
        this.districtList = data;
      }
    },
    onDistrict(value) {
      this.district_name = value.name;
      this.basics_info.address_district_id = value.id;
      this.districtEmpty = true;
      this.showDistrict = false;
    },
    onResidenceAddress(value) {
      this.basics_info.residence_address = value;
      if (this.basics_info.residence_address != "") {
        this.residenceAddressEmpty = true;
      } else {
        this.residenceAddressEmpty = false;
      }
    },
    onEducation(value) {
      this.education_text = value.text;
      this.basics_info.education = value.value;
      this.educationEmpty = true;
      this.showEducation = false;
    },
    onGraduation(value) {
      var date = new Date(value);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      // m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      // d = d < 10 ? ('0' + d) : d;
      this.graduation_date = y + "-" + m + "-" + d;
      this.basics_info.graduation_date = y + "-" + m + "-" + d;
      this.graduateEmpty = true;
      this.showGraduation = false;
    },
    onRelationType(value) {
      this.first_relation_type = value.text;
      this.basics_info.first_relation_type = value.value;
      this.firstRelationEmpty = true;
      this.showFirstRelation = false;
    },
    onFirstName(value) {
      this.basics_info.first_relation_user_name = value;
      if (this.basics_info.first_relation_user_name != "") {
        this.firstNameEmpty = true;
      } else {
        this.firstNameEmpty = false;
      }
    },
    onFirstMobile(value) {
      this.basics_info.first_relation_user_mobile = value;
      if (this.basics_info.first_relation_user_mobile != "") {
        this.firstMobileEmpty = true;
      } else {
        this.firstMobileEmpty = false;
      }
    },
    onSecondRelation(value) {
      this.second_relation_type = value.text;
      this.basics_info.second_relation_type = value.value;
      this.secondRelationEmpty = true;
      this.showSecondRelation = false;
    },
    onSecondName(value) {
      this.basics_info.second_relation_user_name = value;
      if (this.basics_info.second_relation_user_name != "") {
        this.secondNameEmpty = true;
      } else {
        this.secondNameEmpty = false;
      }
    },
    onSecondMobile(value) {
      this.basics_info.second_relation_user_mobile = value;
      if (this.basics_info.second_relation_user_mobile != "") {
        this.secondMobileEmpty = true;
      } else {
        this.secondMobileEmpty = false;
      }
    },
    onEmail(value) {
      this.basics_info.email = value;
      if (this.basics_info.email != "") {
        this.emailEmpty = true;
      } else {
        this.emailEmpty = false;
      }
    },
    onDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth();
      var curDate = date.getDate();
      this.maxDate = new Date(year, month, curDate);
    },
    verifyPhone(val) {
      // var rule = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(val);
      var rule = /^1\d{10}$/.test(val);
      if (rule) {
        this.firstMobileEmpty = true;
      } else {
        this.firstMobileEmpty = false;
      }
      return rule;
    },
    verifySecondPhone(val) {
      // var rule = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(val);
      var rule = /^1\d{10}$/.test(val);
      if (rule) {
        this.secondMobileEmpty = true;
      } else {
        this.secondMobileEmpty = false;
      }
      return rule;
    },
    verifyEmail(val) {
      var rule =
        /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(
          val
        );
      if (rule) {
        this.emailEmpty = true;
      } else {
        this.emailEmpty = false;
      }
      return rule;
    },
    toInfo() {
      this.$emit("nextStep", "identityAuthentication");
    },
  },
};
</script>

<style lang="scss" scoped>
.personalInfo {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  color: #333;
  display: flex;
  flex-direction: column;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #000000;
    color: #FFFFFF;
    height: 100px;
  }

  .cardFrom {
    background: #F9F9F9;
    border-radius: 32px;
  }
  .content {
    flex: 1;
    overflow: hidden;
    display: flex;
    background: #000000;
    flex-direction: column;
    .content-title {
      margin: 30px 30px 0;
      padding: 30px 40px 26px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px 32px 0 0;
      color: #FDA259;
      background: linear-gradient(115deg, #FFE5CC 0%, #FFC191 100%);
      .title-text {
        flex: 1;
        .title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #552C00;
          line-height: 46px;
        }
        .hint {
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #552C00;
        }
      }
      .currentRate {
        width: 120px;
        height: 120px;
      }
    }
    .content-form {
      flex: 1;
      overflow-y: auto;
      padding: 0px 30px;
      border-radius: 32px;
      margin-top: -15px;
      .content-item {
        margin-bottom: 20px;
        background: #FFFFFF;
        border-radius: 32px;
        padding: 36px 0px;
        font-size: 30px;
        color: #333;
        .item-title {
          display: flex;
          align-items: center;
          font-size: 32px;
          margin-bottom: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #552C00;
          line-height: 46px;
        }
      }
      .next-step-button {
        width: 100%;
        height: 90px;
        background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);
        border-radius: 49px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>