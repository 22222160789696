<template>
  <div class="identityAuthentication">
    <div class="go-back">
      <div @click="toInfo">
        <van-icon name="arrow-left" class="icon-left" />
      </div>
    </div>
    <div class="content">
        <div style="background: #000000;">
            <div style="border-radius: 32px">
                <div class="content-title">
                    <div class="title-text">
                        <div class="title">身份认证</div>
                        <div class="hint">身份信息仅做身份认证，请放心使用</div>
                    </div>
                    <van-circle
                            class="currentRate"
                            layer-color="#FFDABD"
                            color="#FF8C10"
                            :stroke-width="160"
                            v-model="currentRate"
                            :rate="currentRate"
                            :speed="100"
                            :text="text"
                    />
                </div>
                <div class="content-form">
                    <!-- 上传照片 -->
                    <div v-if="mode == 'upload'" class="content-item">
                        <div class="item-title">
            <span
                    style="
                padding: 0px 2px;
                height: 15px;
                background-color: #E69B4A;
                margin-right: 10px;
              "
            ></span>
                            <span>请拍摄本人身份证</span>
                        </div>
                        <div class="item-content">
                            <div class="identity-img">
                                <div class="card-img" v-if="!img_authenticated">
                                    <van-uploader
                                            max-count="1"
                                            v-model="frontList"
                                            :after-read="frontBeforeRead"
                                            @delete="delFront"
                                    >
                                        <img src="@/assets/img/zhengmian.png" alt="" />
                                    </van-uploader>
                                    <div class="uploader-status" v-if="showResult">
                                        <img
                                                v-if="upload_real_auth_status == 1"
                                                src="@/assets/img/chenggong.png"
                                                alt=""
                                        />
                                        <img
                                                v-if="upload_real_auth_status == 0"
                                                src="@/assets/img/cuowu.png"
                                                alt=""
                                                @click="delFront"
                                        />
                                        <img
                                                v-if="upload_real_auth_status == 9"
                                                src="@/assets/img/loading.png"
                                                alt=""
                                        />
                                        <span>{{ description }}</span>
                                    </div>
                                </div>
                                <div class="card-img" v-if="img_authenticated">
                                    <img
                                            class="authenticated"
                                            :src="this.frontList[0].url"
                                            alt=""
                                    />
                                </div>
                                <div class="card-hint">*身份证需在有效期内，临时身份证不可用</div>
                            </div>
                            <div class="identity-info" v-if="showInfo">
                                <div class="info-item">
                                    <div class="item-label">本人姓名</div>
                                    <div>{{ identityInfo.real_name }}</div>
                                </div>
                                <div class="info-item">
                                    <div class="item-label">身份证号</div>
                                    <div>
                                        <span>{{ identityInfo.id_card_no }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 手填身份证号 -->
                    <div v-if="mode == 'input'" class="content-item">
                        <div class="item-title">
            <span
                    style="
                padding: 0px 2px;
                height: 10px;
                background-color: #E89E50;
                margin-right: 10px;
              "
            ></span>
                            <span>请填写真实身份证信息</span>
                        </div>
                        <div class="item-content">
                            <van-form>
                                <van-field
                                        v-model="idInfo.real_name"
                                        label="本人姓名"
                                        placeholder="请输入姓名"
                                        input-align="right"
                                        :readonly="input_real_auth_status == 1"
                                />
                                <van-field
                                        v-model="idInfo.id_card_no"
                                        label="身份证号"
                                        placeholder="请输入身份证号"
                                        input-align="right"
                                        :readonly="input_real_auth_status == 1"
                                />
                            </van-form>
                        </div>
                    </div>
                    <div class="consent">
                        <van-checkbox icon-size="16px" v-model="consent"
                        >   <span style="color: #fff">同意</span>
                            <span class="contract" @click.stop="onSignatureAuthorization"
                            >《个人电子签名签署授权书》</span
                            >
                            <span class="contract" @click.stop="onSensitiveInfoAuthorization"
                            >《个人敏感信息处理授权书》</span
                            >
                        </van-checkbox>
                    </div>
                    <div v-if="mode == 'upload'" style="margin: 16px">
                        <div
                                class="submit-button"
                                v-if="consent && frontEmpty && !loading"
                                @click="onSubmit"
                        >
                            下一步
                        </div>
                        <div class="submit-button" v-else-if="loading">...提交中</div>
                        <div class="submit-button" v-else>下一步</div>
                    </div>
                    <div v-if="mode == 'input'" style="margin: 16px">
                        <div
                                class="submit-button"
                                style="background: #E89E50"
                                v-if="consent && !loading"
                                @click="onSubmit"
                        >
                            下一步
                        </div>
                        <div class="submit-button" v-else-if="loading">...提交中</div>
                        <div class="submit-button" v-else>下一步</div>
                    </div>

                    <template v-if="!isIdCardAuthComplete">
                        <div
                                v-if="mode == 'upload' && businessInfo.skip_id_card_image == 1"
                                class="skip-binding"
                        >
                            <span @click="onChangeMode">没身份证照片？手动输入身份证信息</span>
                        </div>
                        <div v-if="mode == 'input' && businessInfo.skip_contact != 1" class="skip-binding">
                            <span @click="onChangeMode">上传身份证照片</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <van-action-sheet
      v-model="showSignatureAuthorization"
      title="个人电子签名签署授权书"
    >
      <div class="stage-content">
        <p>特别提示：</p>
        <p class="stage-text">
          为了保障您的合法权益，在您签署本授权书前请您务必审慎阅读，并充分理解本授权书的全部内容，特别是以加粗形式提示您注意的内容。若您不接受本授权书的任何条款，请您不要点击确认；但在未签署本授权书的情况下，本平台为您提供的服务可能会受到不利影响或者无法实现，您须自行承担相关风险。本授权书经签署后即时生效，且效力具有独立性，不因相关业务合同或条款无效或被撤销而无效或失效。
        </p>
        <p class="stage-text">
          鉴于本人（姓名：_______，身份证：_______）通过蚂蚁融信（北京）科技有限公司及其关联实体运营的平台（具体名称以实际展示的为准，简称“平台”）向为本人提供服务/商品的机构申请赊购服务，本人同意采取线上电子签名方式签署本人在通过平台申请服务过程中需要签署的所有协议、文件（包括赊购协议、服务协议、授权书等文件，具体内容及名称以实际签署的为准，简称“相关协议”），为此本人特做出如下授权：
        </p>
        <p class="stage-text">
          1、本人授权蚂蚁融信（北京）科技有限公司及其关联方（简称“被授权机构”）将本人的个人身份信息（姓名、身份证号、手机号）提供给被授权机构合作的信息服务机构及其合作的第三方机构包括杭州天谷信息科技有限公司等（简称“第三方机构”），通过第三方机构向CA机构申请属于本人的数字证书、并生成本人的电子签章。
        </p>
        <p class="stage-text">
          2、平台展示的相关协议一经本人点击确认或勾选即视为本人对协议内容知悉并同意遵照履行，且本人授权被授权机构通过第三方机构调取本人的数字证书、电子签章签署相关协议，本人认可加盖本人电子签章后的相关协议的效力。
        </p>
        <p class="stage-text">
          3、本人保证本人向被授权机构提供的信息真实、准确、完整、有效，否则因本人提供信息不完整或不真实导致影响合同效力或侵犯其他第三方权益等问题，由本人承担所有的责任，且本人同意赔偿给相关方造成的损失。
        </p>
        <p class="stage-text">
          4、本授权书可在本人使用平台提供的服务过程中多次使用，被授权机构自本人签署本授权之日起可多次依据本授权书而操作执行本授权书项下的活动无需本人另行授权。
        </p>
        <p class="stage-text">
          5、本授权书的授权期限自本人签署本授权书之日起至本人在相关协议项下的义务全部履行完毕之日止。
        </p>
        <p class="stage-text">
          6、本人已知悉并理解本授权书的所有内容（特别是加粗字体内容）以及由此产生的法律后果，本授权书系本人自愿签署，一经本人通过点击确认或勾选即为有效。
        </p>
        <div class="signature">
          <p class="stage-text">授权人：</p>
          <p class="stage-text">UID：</p>
          <p class="stage-text">日期：年 月 日</p>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet
      v-model="showSensitiveInfoAuthorization"
      title="个人敏感信息处理授权书"
    >
      <div class="stage-content">
        <p>特别提示：</p>
        <p class="stage-text">
          为了保障您的合法权益，在您签署本授权书前请您务必审慎阅读，并充分理解本授权书的全部内容，特别是以加粗形式提示您注意的内容。若您不接受本授权书的任何条款，请您不要点击确认；但在未签署本授权书的情况下，本平台为您提供的服务可能会受到不利影响或者无法实现，您须自行承担相关风险。本授权书经签署后即时生效，且效力具有独立性，不因相关业务合同或条款无效或被撤销而无效或失效。
        </p>
        <p class="stage-text">
          一、本人（姓名：_______；身份证号：_______）在通过本平台（具体名称以实际展示的为准，简称“平台”，由蚂蚁融信（北京）科技有限公司及其关联实体运营）向为本人提供服务/商品的机构申请赊购服务时，本人同意并授权蚂蚁融信（北京）科技有限公司及其关联实体（以下简称“被授权机构”或“我们”）收集、使用本人的如下敏感个人信息：
        </p>
        <p class="stage-text">
          1、本人身份信息：身份证正反面照片、活体识别信息；用于核实本人身份的真实性及核实是否是本人操作。身份证正反面照片对本人而言是私密且重要的信息，本人已经充分理解并知悉该等信息提供和使用的风险，这些风险包括但不限于：该等信息被我们依法提供给第三方后被他人不当使用（如本人的身份证照片被他人用于手机号实名登记、银行账户开户办卡等不法目的）的风险；活体信息识别对本人而言是私密且重要的信息，前述信息被我们提供给第三方后可能被他人用于人脸识别/实名认证之外的其他目的，因而导致存在本人的权益被侵害的风险。
        </p>
        <p class="stage-text">
          2、银行账户信息：持卡人、借记卡号、开户行、预留手机号、短信校验码；用于为本人提供卡片校验服务及为本人提供付款相关服务。银行账户信息对本人而言是私密且重要的信息，本人已经充分理解并知悉该等信息提供和使用的风险，这些风险包括但不限于：纳入这些信息对本人的信用评级（评分）、信用报告等结果可能产生不利影响，该等信息被我们依法提供给第三方后被他人不当利用的风险，因本人的信用状况较好而造成本人被第三方推销产品或服务等打扰的风险等。
        </p>
        <p class="stage-text">
          3、地址信息，用于为本人提供订单管理及向本人发送或邮寄通知、文件等。地址信息对本人而言是私密且重要的信息，本人已经充分理解并知悉该等信息提供和使用的风险，这些风险包括但不限于：被第三方不法利用，遭受第三方的骚扰等。
        </p>
        <p class="stage-text">
          4、本人的付款计划信息、付款行为信息、逾期信息、履约情况信息，用于为本人提供账户管理、付款提醒等服务。本人已经充分理解并知悉：前述不良信用信息一旦记录在第三方机构的信息数据库中，有可能会对本人日后的经济活动产生不同程度的不良影响。
        </p>
        <p class="stage-text">
          二、基于被授权机构为本人提供服务需要、因本人申请赊购服务导致的纠纷发生时的举证需要、法律法规及监管要求等，本人授权被授权机构保存本人的上述信息。
        </p>
        <p class="stage-text">
          三、本授权书自本人签署时生效。授权期限为自本授权书生效之日起至本人与被授权机构的权利义务全部终结之日后的三年止，因法律法规有特别规定，被授权机构有权按照其规定执行。但在授权有效期内被授权机构已实施的行为及依其自身性质需在授权终止后实施或需持续实施的行为，不受授权终止的影响。
        </p>
        <p class="stage-text">
          四、因本授权书产生的争议或纠纷，本人同意协商解决，协商不成的任一方有权提交本协议签署地北京市海淀区有管辖权的法院通过诉讼解决。
        </p>
        <p class="stage-text">
          五、本人已知悉并理解本授权书的所有内容（特别是加粗字体内容）以及由此产生的法律后果，本授权书系本人自愿签署，一经本人通过点击确认或勾选即为有效。
        </p>
        <div class="signature">
          <p class="stage-text">授权人：</p>
          <p class="stage-text">UID：</p>
          <p class="stage-text">日期：年 月 日</p>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {
  checkIdCardByImg,
  checkIdCardByCode,
  tmpSaveApplication,
} from "@/api/application.js";
import { getOSSConf } from "@/api/util";
import axios from "axios";
import { Toast } from "vant";

export default {
  name: "identityAuthentication",
  props: ["tmp_save_id", "business_id", "infoUser", "businessInfo"],
  data() {
    return {
      currentRate: 40,
      frontList: [],
      consent: true,
      showSignatureAuthorization: false,
      showSensitiveInfoAuthorization: false,
      id_card_image: "",
      frontEmpty: false,
      showInfo: false,
      identityInfo: {},
      id_card_is_writable: 1,
      real_auth_status: 0,
      upload_real_auth_status: 0,
      input_real_auth_status: 0,
      description: "",
      showResult: false,
      cardEmpty: false,
      loading: false,
      card_tmp_save_id: "",
      img_authenticated: false,

      tmpUploadImgUrl: "",
      isIdCardAuthComplete: false,

      mode: "",
      idInfo: {},
    };
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + "%";
    },
  },
  mounted() {
    this.initData();
  },

  methods: {
    async initData() {
      var data = this.infoUser;

      // 提示 年龄不符合
      if(data.id_card_no){
        this.getAgeJudgment(data.id_card_no, true);
      }

      let mode = "upload";
      if (data.id_card_image_url) {
        this.img_authenticated = true;
        this.frontList = [
          {
            url: data.id_card_image_url,
          },
        ];
        this.id_card_image = data.id_card_image;
        this.identityInfo = {
          real_name: data.real_name,
          id_card_no: data.id_card_no,
        };
        this.frontEmpty = true;
        this.showInfo = true;
        this.showResult = true;
        this.upload_real_auth_status = 1;
        this.description = "";
        this.card_tmp_save_id = "";
        this.isIdCardAuthComplete = true;
        if (this.businessInfo.skip_contact == 1) {
          mode = "input";
          this.idInfo = {
            real_name: data.real_name,
            id_card_no: data.id_card_no,
          };
        } else {
          mode = "upload";
        }
      } else if (data.id_card_no) {
        this.idInfo = {
          real_name: data.real_name,
          id_card_no: data.id_card_no,
        };
        this.frontEmpty = true;
        this.input_real_auth_status = 1;
        this.isIdCardAuthComplete = true;
        mode = "input";
      } else {
        if (this.businessInfo.skip_contact == 1) {
          mode = "input";
          // this.idInfo = {
          //   real_name: data.real_name,
          //   id_card_no: data.id_card_no,
          // };
        } else {
          mode = "upload";
        }
      }
      if (!this.businessInfo.skip_id_card_image) {
        mode = "upload";
      }
      this.mode = mode;
    },

    onSubmit() {
      if (this.mode == "upload") {
        this.submitImg();
      }
      if (this.mode == "input") {
        this.submitInput();
      }
    },
    async submitImg() {
      // 检查年龄
      if(this.identityInfo.id_card_no){
        var ageJudgment = this.getAgeJudgment(this.identityInfo.id_card_no, true);
        if(!ageJudgment){
          return
        }
      }

      // 准备参数
      var parmes = {
        step: 2,
        tmp_save_id: this.tmp_save_id,
      };
      if (this.card_tmp_save_id) {
        parmes.card_tmp_save_id = this.card_tmp_save_id
      }
      
      this.loading = true;
      const { code, data } = await tmpSaveApplication(parmes);
      this.loading = false;

      if (code == 200) {
        if (!this.infoUser.id_card_no) {
          this.infoUser.real_name = this.identityInfo.real_name;
          this.infoUser.id_card_no = this.identityInfo.id_card_no;
        }
        if (!this.infoUser.id_card_image_url) {
          this.infoUser.id_card_image_url = this.tmpUploadImgUrl;
          this.tmpUploadImgUrl = "";
        }

        this.description = data.description;
        this.upload_real_auth_status = data.real_auth_status;
        this.isIdCardAuthComplete = true;

        if (data.real_auth_status == 1) {
          this.frontEmpty = true;
          this.showInfo = true;
          this.$emit("tmpSaveId", this.tmp_save_id);
          if (this.businessInfo.skip_contact == 1) {
            this.$emit("nextStep", "bindingCard");
          } else {
            this.$emit("nextStep", "personalInfo");
          }
        } else {
          this.frontEmpty = false;
          this.showInfo = false;
        }
      }
    },
    async submitInput() {
      if(this.idInfo.id_card_no){
        var ageJudgments = this.getAgeJudgment(this.idInfo.id_card_no, true);
        if (!ageJudgments) {
          return
        }
      }

      if (this.input_real_auth_status != 1) {
        const params = {
          name: this.idInfo.real_name,
          idCard: this.idInfo.id_card_no,
        };
        const res = await checkIdCardByCode(params);
        if (res.code == 200) {
          this.input_real_auth_status = res.data.real_auth_status;
          if (res.data.real_auth_status == 1) {
            this.card_tmp_save_id = res.data.card_tmp_save_id;
          }
        }
      }

      if (this.input_real_auth_status != 1) return;

      const parmes = {
        step: 2,
        tmp_save_id: this.tmp_save_id,
      };
      if (this.card_tmp_save_id) {
        parmes.card_tmp_save_id = this.card_tmp_save_id;
      }

      this.loading = true;
      const { code, data } = await tmpSaveApplication(parmes);
      this.loading = false;

      if (code == 200) {
        if (!this.infoUser.id_card_no) {
          this.infoUser.real_name = this.idInfo.real_name;
          this.infoUser.id_card_no = this.idInfo.id_card_no;
        }
        this.description = data.description;
        this.input_real_auth_status = data.real_auth_status;
        this.isIdCardAuthComplete = true;

        if (data.real_auth_status == 1) {
          this.$emit("tmpSaveId", this.tmp_save_id);
          if (this.businessInfo.skip_contact == 1) {
            this.$emit("nextStep", "bindingCard");
          } else {
            this.$emit("nextStep", "personalInfo");
          }
        }
      } else {
        this.input_real_auth_status = 0;
      }
    },

    frontBeforeRead(file) {
      // 1500000
      console.log(file);
      // if (file.file.size > 50000) {
      //   // 创建Canvas对象(画布)
      //   let canvas = document.createElement("canvas");
      //   // 获取对应的CanvasRenderingContext2D对象(画笔)
      //   let context = canvas.getContext("2d");
      //   // 创建新的图片对象
      //   let img = new Image();
      //   // 指定图片的DataURL(图片的base64编码数据)
      //   img.src = file.content;
      //   // 监听浏览器加载图片完成，然后进行进行绘制
      //   img.onload = () => {
      //     // 指定canvas画布大小，该大小为最后生成图片的大小
      //     canvas.width = 540;
      //     canvas.height = 312;
      //     /* drawImage画布绘制的方法。(0,0)表示以Canvas画布左上角为起点，400，300是将图片按给定的像素进行缩小。
      //   如果不指定缩小的像素图片将以图片原始大小进行绘制，图片像素如果大于画布将会从左上角开始按画布大小部分绘制图片，最后的图片就是张局部图。*/
      //     context.drawImage(img, 0, 0, 540, 312);
      //     // 将绘制完成的图片重新转化为base64编码，file.file.type为图片类型，0.92为默认压缩质量
      //     file.content = canvas.toDataURL(file.file.type, 0.92);
      //     // 最后将base64编码的图片保存到数组中，留待上传。
      //     console.log(file)
      this.uploadOSSImage(file.file);
      // };
      // } else {
      // this.uploadOSSImage(file.file);
      // }
      return true;
    },
    delFront() {
      this.frontList = [];
      this.id_card_image = "";
      this.frontEmpty = false;
      this.showResult = false;
      this.showInfo = false;
    },
    async uploadOSSImage(file) {
      var that = this;
      const { data } = await getOSSConf();
      var extension = "";
      if (/.*(\.[^\\.]*)/.test(file.name)) {
        extension = RegExp.$1;
      }
      const filename = data.filename;
      const osspath = data.policy.dir + filename + extension;

      const imgUrl = data.domain + "/" + osspath;

      let formData = new FormData();
      formData.append("key", osspath);
      formData.append("OSSAccessKeyId", data.policy.accessid);
      formData.append("policy", data.policy.policy);
      formData.append("signature", data.policy.signature);
      formData.append("success_action_status", 200);
      formData.append("file", file);
      axios({
        url: data.policy.host,
        method: "post",
        processData: false,
        data: formData,
      }).then((res) => {
        if (res.status == 200) {
          that.id_card_image = osspath;
          that.checkIdCardByImg(osspath, imgUrl);
        }
      });
    },
    async checkIdCardByImg(id_card_image, fullUrl) {
      var that = this;
      that.showResult = true;
      that.description = "识别中...";
      that.upload_real_auth_status = 9;
      const parmes = {
        id_card_image: id_card_image,
      };
      const { code, data } = await checkIdCardByImg(parmes);
      if (code == 200) {
        that.upload_real_auth_status = data.real_auth_status;
        that.description = data.description;
        if (data.real_auth_status == 1) {
          this.card_tmp_save_id = data.card_tmp_save_id;
          that.showInfo = true;
          that.frontEmpty = true;
          that.identityInfo = {
            real_name: data.real_name,
            id_card_no: data.id_card_no,
          };
          this.tmpUploadImgUrl = fullUrl;
          this.getAgeJudgment(data.id_card_no, true);
        } else {
          that.frontEmpty = false;
          that.showInfo = false;
        }
      }
    },

    onChangeMode() {
      if (this.mode == "upload") {
        this.mode = "input";
      } else if (this.mode == "input") {
        this.mode = "upload";
      }
    },
    toInfo() {
      this.$emit("nextStep", "product-info");
    },
    onSignatureAuthorization() {
      this.showSignatureAuthorization = true;
    },
    onSensitiveInfoAuthorization() {
      this.showSensitiveInfoAuthorization = true;
    },
    getAgeJudgment(idCard, autoToast) {
      var age = this.analyzeIDCard(idCard);
      var min_age = this.businessInfo.apply_bill_min_age;
      var max_age = this.businessInfo.apply_bill_max_age;

      if(age < min_age){
        if(autoToast){
          Toast("年龄不能低于"+min_age+"岁");
        }
        return false
      }else if(age > max_age){
        if(autoToast){
          Toast("年龄不能超过"+max_age+"岁");
        }
        return false
      }
      return true
    },
    analyzeIDCard(idCard) {
      if((idCard || "").length < 15){
        return 0
      }
      
      // 获取出生日期
      const yearBirth  = idCard.substring(6, 10);
      const monthBirth = idCard.substring(10, 12);
      const dayBirth   = idCard.substring(12, 14);
      // 获取当前年月日并计算年龄
      const myDate = new Date();
      const monthNow = myDate.getMonth() + 1;
      const dayNow = myDate.getDate();

      let age = myDate.getFullYear() - yearBirth;
      
      if (
        monthNow < monthBirth ||
        (monthNow == monthBirth && dayNow < dayBirth)
      ) {
        age--;
      }
      
      return age;
    },
  },
};
</script>

<style lang="scss" scoped>
.identityAuthentication {
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  color: #333;
  display: flex;
  flex-direction: column;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #000000;
    color: #FFFFFF;
    height: 100px;
  }
  .content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .content-title {
      margin: 30px 30px 0;
      padding: 30px 40px 26px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px 32px 0 0;
      color: #FDA259;
      background: linear-gradient(115deg, #FFE5CC 0%, #FFC191 100%);
      .title-text {
        flex: 1;
        .title {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #552C00;
          line-height: 46px;
        }
        .hint {
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #552C00;
        }
      }
      .currentRate {
        width: 120px;
        height: 120px;
      }
    }
    .content-form {
      flex: 1;
      overflow-y: auto;
      padding: 0px 30px;
      border-radius: 32px;
      margin-top: -15px;
      .content-item {
        margin-bottom: 20px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px #f5f5f5;
        border-radius: 20px;
        padding: 15px 0px;
        font-size: 30px;
        color: #333;
        .item-title {
          display: flex;
          align-items: center;
          color: #333;
          font-size: 30px;
          margin-top: 30px;
          font-weight: 600;
        }
        .item-content {
          .identity-img {
            display: flex;
            flex-direction: column;
            align-items: center;
            .card-img {
              margin: 40px 0px 0px 0px;
              position: relative;
              img {
                width: 540px;
                height: 312px;
                border-radius: 10px;
              }
              .authenticated {
                width: 540px;
                height: 312px;
                border-radius: 10px;
                object-fit: cover;
              }
              .uploader-status {
                top: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                // width: 540px;
                // height: 312px;
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                font-weight: 400;
                color: #ffffff;
                img {
                  width: 81px;
                  height: 81px;
                }
                span {
                  margin: 10px 0px;
                }
              }
            }
            .card-hint {
              margin: 30px 0px;
              font-size: 24px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #BF7034;
              line-height: 46px;
            }
          }
          .identity-info {
            padding: 0px 30px;
            .info-item {
              display: flex;
              padding: 14px 10px;
              border-bottom: 1px solid #e7e7e7;
              .item-label {
                flex: 1;
              }
              .idCard-no {
                border: 0px;
                text-align: right;
              }
            }
            .info-item:last-child {
              border: 0px;
              // font-size: 24px;
            }
          }
        }
      }
      .consent {
        padding: 30px;
        color: #fffdef;
        .contract {
          color: #E89E50;
        }
      }
      .skip-binding {
        margin: 50px 0px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #E89E50;
        font-size: 20px;
      }
      .submit-button {
        width: 100%;
        height: 90px;
        background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);
        border-radius: 49px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .stage-content {
    padding: 30px;
    .stage-text {
      text-indent: 2em;
    }
    .signature {
      text-align: right;
    }
  }

  /deep/ .van-uploader__upload,
  /deep/ .van-image,
  /deep/ .van-uploader__preview-image {
    width: 270px;
    height: 156px;
  }
  /deep/ .van-uploader__upload-text {
    margin-top: 8px;
    color: #969799;
    font-size: 200px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #1da4f2;
    color: #fff;
    text-align: center;
  }
  /deep/ .van-uploader__upload,
  /deep/ .van-image {
    background: #e8f6fe;
    border-radius: 5px;
    overflow: hidden;
  }
  /deep/ .van-icon__image {
    width: 4em;
    height: 3em;
    -o-object-fit: contain;
    object-fit: contain;
    margin-bottom: 23px;
  }
  /deep/ .van-uploader__preview {
    margin: 0;
  }
  /deep/.van-uploader__preview-delete {
    z-index: 99;
  }
}
</style>